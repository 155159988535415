<template>
  <v-menu offset-y>
    <template #activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <slot name="activators" />
      </div>
    </template>
    <v-list>
      <v-list-item class="item-list justify-center">
        <div class="title-container">
          <BaseCaption
            text="secondary"
            normal-size
            v-text="
              documentControlLists.length
                ? $t('utils.chooseControl')
                : $t('utils.noControl')
            "
          />
          <div v-if="documentControlLists.length" class="underline-div" />
        </div>
      </v-list-item>
      <v-list-item
        v-for="(control, index) in documentControlLists"
        :key="index"
        class="item-list"
      >
        <div class="content" @click.stop="generateControl(control)">
          <BaseParagraph type="primary" v-text="control.name" />
        </div>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "FolderGenerateControl",
  computed: {
    ...mapState({
      documentControlLists: (state) => state.organization.documentControlLists,
      offersRecordsCosmoforms: (state) => state.folder.offersRecordsCosmoforms,
    }),
  },
  methods: {
    async generateControl(data) {
      try {
        // create control
        const response = await this.$store.dispatch(
          "organization/postOrganizationDocumentControl",
          {
            organizationId: this.$route.params.organizationId,
            folderId: this.$route.params.folderId,
            wizideeControlId: data.id,
          }
        );
        this.$store.commit("folder/SET_FOLDER_RECORDS_OFFERS_COSMOFORMS", [
          ...this.offersRecordsCosmoforms,
          { ...response, type: "CONTROL" },
        ]);

        this.$store.dispatch("snackbar/active", {
          message: this.$t("control.created"),
          type: "SUCCESS",
        });
        // run control
        await this.$store.dispatch(
          "organization/postOrganizationDocumentControlRun",
          {
            organizationId: this.$route.params.organizationId,
            folderId: this.$route.params.folderId,
            documentControlId: response.id,
          }
        );
        // refetch ranking to get updates
        await this.$store.dispatch("folder/getOrganizationOffersRecordFolder", {
          organizationId: this.$route.params.organizationId,
          folderId: this.$route.params.folderId,
        });
        this.$store.dispatch("snackbar/active", {
          message: this.$t("control.finished"),
          type: "SUCCESS",
        });
      } catch (error) {
        if (error.response) {
          const { errorMessage } = error.response?.reasons;
          return this.$store.dispatch("snackbar/active", {
            message: this.$t("utils.missingDocuments", {
              documents: errorMessage.join(" ,"),
            }),
            type: "ERROR",
          });
        }
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.oopsError"),
          type: "ERROR",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.item-list {
  display: flex;
  align-items: center;
  height: 33px;
  width: 220px;
}
.title-container {
  position: relative;
}
.underline-div {
  position: absolute;
  left: 30%;
  width: 60px;
  height: 0px;
  border: 0.5px solid #707080;
}
.content {
  width: 100%;
  padding: 2%;
  &:hover {
    background: rgba(155, 189, 255, 0.2);
    border-radius: 5px;
    cursor: pointer;
  }
}
</style>
