/**
 * Types of datatypes Model
 */
export const PersonTypes = {
  // Personne physique (n'appartient à aucune organisation)
  NaturalPerson: "NATURAL_PERSON",
  // Personne morale (appartient à une ou plusieurs organisation)
  LegalPerson: "LEGAL_PERSON",
};

export const VignetteSignatureProperty = {
  height: 70,
  width: 180,
};

/**
 * Access statuses for the welcome page returned by the access endpoint
 * The make the page redirect when loaded
 */
export const WelcomeAccessStatus = {
  // The campaign status is different of "IN_PROGRESS"
  CampaignNotReady: "campaign-not-ready",
  // The record is reserved to a dedicated user and it's not the connected one
  RecordReserved: "record-reserved",
  // The campaign has once property at true and the user already has an answer
  AlreadyAnswered: "already-answered",
  // All of the above are respected
  Ok: "ok",
};

/**
 * Action statuses for the welcome page returned by the access endpoint
 * They make the action button of the welcome page redirect to differents
 * actions
 */
export const WelcomeActionStatus = {
  // User is not connected and the campaign isn't anonymous
  NeedAuth: "need-auth",
  // On doit créer un enregistrement et rediriger vers la réponse à un parcours
  CreateRecord: "create-record",
  // On redirige directement vers le parcours
  Answer: "answer",
};

/**
 * Orgalization folder offers types
 */
export const OfferType = {
  Consultation: "consultation",
  Validation: "validation",
  Signature: "signature",
  Payment: "payment",
};

export const EMAIL_STATUS = {
  PENDING: "PENDING",
  IN_PROGRESS: "IN_PROGRESS",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
  SENT: "SENT",
  CLICKED: "CLICKED",
  SPAM: "SPAM",
  OPEN: "OPEN",
  HARD_BOUNCE: "HARD_BOUNCE",
  SOFT_BOUNCE: "SOFT_BOUNCE",
};

export const OFFER_TYPES = {
  CONSULT: "CONSULT",
  VALIDATION: "VALIDATION",
  SIGNATURE: "SIGNATURE",
  MIXTE: "MIXTE",
};

export const ANSWER_VALIDATION_LEVEL = {
  PENDING: "PENDING",
  VALIDATED: "VALIDATED",
  INVALIDATED: "INVALIDATED",
};

export const COLLABORATOR_ROLE = {
  MANAGER: "MANAGER",
  MEMBER: "MEMBER",
};
