<template>
  <div class="actions_container">
    <template v-if="loading">
      <div class="action_title">
        <v-skeleton-loader class="skeleton" type="text" />
      </div>
      <div class="static_actions">
        <v-skeleton-loader class="action_btn_skeleton" type="text" />
        <v-skeleton-loader class="action_btn_skeleton" type="text" />
        <v-skeleton-loader class="action_btn_skeleton" type="text" />
      </div>
      <div class="actions_separator loading" />
      <div class="dynamic_actions">
        <v-skeleton-loader class="action_btn_skeleton" type="text" />
        <v-skeleton-loader class="action_btn_skeleton" type="text" />
      </div>
    </template>
    <template v-else>
      <div class="action_header">
        <div class="action_title" v-text="$t('utils.actions')" />
        <BaseIcon icon="$mdiDotsHorizontal" @click.stop="isOpen = !isOpen" />
      </div>
      <div v-if="isOpen" ref="dropdown" class="dropdown">
        <li
          class="dropdown_btn d-flex align-center"
          :disabled="isArchivedFolder"
          @click="
            $store.commit('offer/setFolderOfferCreateAndEditDialog', true)
          "
        >
          <BaseIcon class="icon mr-2" icon="$mdiDraw" color="#707080" />{{
            $t("utils.sendOffer")
          }}
        </li>
        <li
          class="dropdown_btn d-flex align-center"
          :disabled="isArchivedFolder"
        >
          <!-- template -->
          <div class="drop-menu">
            <folder-generate-control>
              <template #activators>
                <BaseIcon
                  class="icon mr-2"
                  icon="$mdiFilePdfBox"
                  color="#707080"
                />{{ $t("utils.documentControl") }}
              </template>
            </folder-generate-control>
          </div>
        </li>
        <li
          class="dropdown_btn"
          :disabled="isArchivedFolder"
          @click="openAskInformationDialog()"
        >
          <span class="d-flex align-center"
            ><BaseIcon
              class="icon mr-2"
              icon="$mdiBullhornOutline"
              color="#707080"
            />{{ $t("folder.askInformation") }}</span
          >
        </li>
        <li
          class="dropdown_btn"
          :disabled="isArchivedFolder"
          @click="
            $store.commit('folder/setModalFolderChangeStatusDialog', true)
          "
        >
          <span class="d-flex align-center"
            ><BaseIcon
              color="#707080"
              class="icon mr-2"
              icon="$mdiChevronRightCircleOutline"
            />{{ $t("folder.workflow.changeStatus") }}</span
          >
        </li>
      </div>
      <template>
        <div
          v-if="status.actions && status.actions.length"
          class="actions_separator"
        />

        <div v-if="status.actions && status.actions.length">
          <div
            v-for="(action, index) in status.actions"
            :key="'action' + index"
            :class="`action-${action.slug}`"
          >
            <div
              v-if="
                action.slug == 'generate_docx' ||
                action.typeAction == 'GENERATE_SPECIFIC_DOCX'
              "
            >
              <FolderGenerateDocxMenu>
                <template #activators>
                  <button
                    class="action_btn"
                    :color="getStatusColor(action)"
                    :disabled="isArchivedFolder"
                    @click="runAction(action)"
                  >
                    <BaseIcon
                      v-if="getActionIcon(action)"
                      class="icon"
                      :icon="getActionIcon(action)"
                    />
                    <span class="label" v-text="action.name" />
                  </button>
                </template>
              </FolderGenerateDocxMenu>
            </div>

            <div v-else>
              <button
                class="action_btn"
                :color="getStatusColor(action)"
                :disabled="isArchivedFolder"
                @click="runAction(action)"
              >
                <BaseIcon
                  v-if="getActionIcon(action)"
                  class="icon"
                  :icon="getActionIcon(action)"
                />
                <span class="label" v-text="action.name" />
              </button>
            </div>
          </div>
        </div>
      </template>
    </template>
    <folder-send-to-ged-warning-dialog
      :dialog-state="isWarningModalOpen"
      @close="isWarningModalOpen = false"
    />
    <SendToEnoxaErrorDialog
      :dialog-state="enoxaErrorDialogState"
      :error-code="errorCode"
      @close="enoxaErrorDialogState = false"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FolderGenerateDocxMenu from "./FolderGenerateDocxMenu.vue";
import { mapState } from "vuex";
import FolderSendToGedWarningDialog from "./FolderSendToGedWarningDialog.vue";
import SendToEnoxaErrorDialog from "./SendToEnoxaErrorDialog.vue";
import FolderGenerateControl from "./FolderGenerateControl.vue";

export default {
  name: "FolderDetailActions",
  components: {
    FolderGenerateDocxMenu,
    FolderSendToGedWarningDialog,
    SendToEnoxaErrorDialog,
    FolderGenerateControl,
  },
  props: {
    status: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
    },
  },
  data() {
    return {
      isFetchingActions: false,
      loadingStates: [],
      isWarningModalOpen: false,
      isOpen: false,
      enoxaErrorDialogState: false,
      errorCode: "",
    };
  },
  computed: {
    ...mapGetters({ isArchivedFolder: "folder/isArchivedFolder" }),
    ...mapState({
      templateId: (state) => state.workflow.templateId,
      activeFolder: (state) => state.folder.activeFolder,
      templateMail: (state) => state.folder.templateMail,
      documentControlLists: (state) => state.organization.documentControlLists,
      offersRecordsCosmoforms: (state) => state.folder.offersRecordsCosmoforms,
    }),
  },
  created() {
    this.generateContol();
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  },

  methods: {
    isLoading(actionID) {
      return this.loadingStates.some((value) => value == actionID);
    },
    handleClickOutside(event) {
      if (this.$refs.dropdown && !this.$refs.dropdown.contains(event.target)) {
        this.isOpen = false;
      }
    },
    goToFullData() {
      open(
        `/organization/${this.$route.params.organizationId}/folder/${this.$route.params.folderId}/client-full-data/`
      );
    },
    async sendToDPAE() {
      try {
        await this.$store.dispatch("folder/postDPAE", {
          organizationId: this.$route.params.organizationId,
          folderId: this.$route.params.folderId,
        });
        this.$store.dispatch("snackbar/active", {
          message: "DPAE transmise",
          type: "SUCCESS",
        });
      } catch (error) {
        if (error?.response?.data?.code === "missing-datatypes-dpae") {
          this.$store.commit("folder/setDPAEMissingFields", [
            ...error?.response?.data?.details?.missing,
          ]);
          return this.$store.commit("folder/SET_DPAE_DIALOG_OPEN", true);
        }
        return this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.oopsError"),
          type: "ERROR",
        });
      }
    },
    async generateDocx(actionId) {
      await this.$store.dispatch("organization/getOrganizationDocxTemplate", {
        organizationId: this.$route.params.organizationId,
      });

      this.loadingStates.splice(
        this.loadingStates.findIndex((val) => val == actionId),
        1
      );
      return this.$store.commit("organization/setModalGenerateDocxOpen", true);
    },

    async runAction(action) {
      if (!action.typeAction) {
        switch (action.slug) {
          case "visualize-data":
            return this.goToFullData();

          case "sent_to_ged":
            return await this.sentToGed();
          case "send_to_dpae":
            return await this.sendToDPAE();
          case "generate_docx":
            this.loadingStates.push(action.id);
            return this.generateDocx(action.id);
          case "archive-folder":
            this.loadingStates.push(action.id);
            return this.$store.commit(
              "folder/SET_MODAL_FOLDER_ARCHIVE_STATUS",
              true
            );
        }
      }
      switch (action.typeAction) {
        case "FOLDER_EMAIL":
          return this.openSendMailDialog(action);
        case "CAMPAIGN":
          return this.openAskInformationDialog(action.extra.id);
        case "COSMOFORM":
          return this.openSendCosmoformDialog(action.extra);
        case "OFFER":
          return this.openOfferTemplate(action.extra.id);
        case "STATUS_CHANGE":
          return this.changeStatus(action);
        case "GENERATE_SPECIFIC_DOCX":
          return this.generateSpecificDocx(action.extra);
        case "DOCUMENT_CONTROL":
          return this.runControl(action);
      }
    },
    async changeStatus(action) {
      const { collaborator, status, showModal } = action.extra;
      if (showModal) {
        this.$store.commit("folder/setModalFolderChangeStatusDialog", true);
        this.$store.commit("folder/setTemplateStatus", action.extra);
        return;
      }
      if (!collaborator) {
        return this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.snackbar.errorCollaborator"),
          type: "ERROR",
        });
      }
      const payloadSetCollaborator = {
        assign: collaborator,
        status: status,
      };
      try {
        const res = await this.$store.dispatch(
          "folder/setFolderCollaboratorStatus",
          {
            organizationId: this.$route.params.organizationId,
            folderId: this.$route.params.folderId,
            payload: payloadSetCollaborator,
          }
        );
        // update current active folder
        this.$store.commit("folder/SET_ACTIVE_FOLDER", {
          ...this.activeFolder,
          ...res.data,
        });
        this.$store.dispatch("snackbar/active", {
          message: this.$t("folder.statusForm.success"),
          type: "SUCCESS",
        });
      } catch (error) {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("folder.statusForm.error"),
          type: "ERROR",
        });
      }
    },
    async generateControl(data) {
      try {
        // create control
        const response = await this.$store.dispatch(
          "organization/postOrganizationDocumentControl",
          {
            organizationId: this.$route.params.organizationId,
            folderId: this.$route.params.folderId,
            wizideeControlId: data.id,
          }
        );
        this.$store.commit("folder/SET_FOLDER_RECORDS_OFFERS_COSMOFORMS", [
          ...this.offersRecordsCosmoforms,
          { ...response, type: "CONTROL" },
        ]);

        this.$store.dispatch("snackbar/active", {
          message: this.$t("control.created"),
          type: "SUCCESS",
        });
        // run control
        await this.$store.dispatch(
          "organization/postOrganizationDocumentControlRun",
          {
            organizationId: this.$route.params.organizationId,
            folderId: this.$route.params.folderId,
            documentControlId: response.id,
          }
        );
      } catch (error) {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.oopsError"),
          type: "ERROR",
        });
      }
    },
    async runControl(action) {
      try {
        const schemas = action.validationSchemas?.map(
          async (schema) => await this.generateControl(schema)
        );
        await Promise.all(schemas);
        // refetch ranking to get updates
      } catch (error) {
        if (error.response) {
          const { validationSchema } = error.response;
          return this.$store.dispatch("snackbar/active", {
            message: this.$t("utils.missingDocuments", {
              documents: validationSchema.join(" ,"),
            }),
            type: "ERROR",
          });
        }
      }

      await this.$store.dispatch("folder/getOrganizationOffersRecordFolder", {
        organizationId: this.$route.params.organizationId,
        folderId: this.$route.params.folderId,
      });
      this.$store.dispatch("snackbar/active", {
        message: this.$t("control.finished"),
        type: "SUCCESS",
      });
    },
    async sentToGed() {
      try {
        await this.$store.dispatch("organization/sentToGED", {
          organizationId: this.$route.params.organizationId,
          folderId: this.$route.params.folderId,
        });
        return this.$store.dispatch("snackbar/active", {
          message: "Documents envoyés en GED",
          type: "SUCCESS",
        });
      } catch (error) {
        const { code } = error.response.data;
        if (
          [
            "cosmo_channel-or-doc_date-missing",
            "cosmo_channel-and-doc_date-answers-are-required",
            "doc_date-format-must-be-dd/mm/yyyy",
          ].includes(code)
        ) {
          this.errorCode = code;
          this.enoxaErrorDialogState = true;
          return;
        }
        let msg = "Oups, une erreur est survenue";
        if (code === "missing-value-on-enoxa-reference-field") {
          return (this.isWarningModalOpen = true);
        }
        switch (code) {
          case "organization-does-not-have-enoxa":
            msg = "Votre organisation n’est pas client de Enoxa";
            break;
          case "enoxa-login-error":
            msg =
              "Il y a eu une erreur lors du login enoxa pour obtenir un token";
        }
        return this.$store.dispatch("snackbar/active", {
          message: msg,
          type: "ERROR",
        });
      }
    },
    generateSpecificDocx(template) {
      this.$store.commit("organization/setOrganizationDocxTemplates", template);
    },
    openSendMailDialog(action) {
      const template = {
        ...action.extra,
        actionId: action.id,
      };
      this.$store.commit("folder/setTemplateMail", template);
      this.$store.commit("folder/setSendMailDialogOpen", true);
    },
    openOfferTemplate(templateId) {
      this.$store.commit("offer/SET_ACTIVE_OFFER_TEMPLATE_ID", templateId);
      this.$store.commit("offer/setFolderOfferCreateAndEditDialog", true);
    },
    openAskInformationDialog(campaignId = null) {
      this.$store.commit("folder/SET_ACTIVE_COMPAIGN_ID", campaignId);
      this.$store.commit("folder/setAskInformationDialog", true);
    },
    openSendCosmoformDialog(cosmoformTemplate) {
      this.$store.commit("workflow/setActiveStatus", this.status);
      this.$store.commit(
        "folder/SET_ACTIVE_QUESTIONS",
        this.status.questions.filter((q) =>
          cosmoformTemplate.questionIds.find((id) => q.id == id)
        )
      );
      this.$store.commit(
        "folder/SET_COSMO_FORM_TEMPLATE_ID",
        cosmoformTemplate.id
      );
      this.$store.commit("folder/SET_MODAL_SEND_COSMO_FORM", true);
      this.$store.commit("folder/setIsCosmoformAction", true);
    },
    async generateContol() {
      await this.$store.dispatch(
        "organization/getOrganizationDocumentControl",
        this.$route.params.organizationId
      );
    },
    getActionIcon(action) {
      if (!action.typeAction) {
        switch (action.slug) {
          case "visualize-data":
            return "$mdiEye";
          case "generate_docx":
            return "$mdiFilePdfBox";
          case "archive-folder":
            return "$mdiArchive";
        }
      }
      switch (action.typeAction) {
        case "FOLDER_EMAIL":
          return "$mdiHelpCircle";
        case "CAMPAIGN":
        case "COSMOFORM":
        case "OFFER":
          return "$mdiBullhornOutline";
      }
      return "";
    },
    getStatusColor(action) {
      if (action.successCounter > 0) return "success";
      if (action.failCounter > 0) {
        return "secondary";
      } else if (action.inProgressCounter > 0) {
        return "warning";
      }
      return "primary";
    },
  },
};
</script>

<style lang="scss" scoped>
.actions_container {
  width: 100%;
  padding: 16px;
  background: rgba(235, 235, 235, 0.2);
  border-radius: 5px;
  min-height: 100%;
  position: relative;
  .action_header {
    display: flex;
    align-items: center;
  }

  .dropdown {
    width: 227px;
    height: 167px;
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 8px;
    border-radius: 5px;
    background: #fff;
    box-shadow: 0px 6px 10px 0px rgba(46, 45, 193, 0.06),
      0px 2px 4px 0px rgba(46, 45, 193, 0.1);
    right: 3px;
    top: 15px;
    position: relative;

    .dropdown_btn {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      height: 45px;
      background-color: #ffffff !important;
      font-size: 14px !important;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400 !important;
      line-height: 16px;
      .drop-menu {
        margin: 0 6px;
      }

      &:hover {
        color: #2536cc;
        background: #f4f8ff !important;
        border-radius: 5px;
        cursor: pointer;
        ::v-deep .v-icon__svg {
          color: #2536cc;
        }
      }

      .icon {
        ::v-deep {
          .v-icon__svg {
            width: 18px;
            height: 18px;

            :hover {
              color: #2536cc !important;
            }
          }
        }
      }

      & > * {
        margin: 0 6px;
      }

      &:disabled {
        & > .icon {
          &::v-deep {
            .v-icon {
              color: #707080;
            }
          }
        }

        color: #707080;
      }
    }
  }
  .action_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 48px;
    background: #ffffff;
    box-shadow: 0px 2px 2px rgba(0, 15, 144, 0.2),
      0px 5px 15px rgba(20, 20, 243, 0.1);
    border-radius: 5px;
    margin-top: 24px;
    padding: 16px;
    font-size: 12px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: #2536cc;
    .label {
      color: inherit;
      line-height: inherit;
      font: inherit;
    }

    .label {
      font: inherit;
      color: inherit;
    }

    & > .icon {
      &::v-deep {
        .v-icon {
          width: 16px;
          height: 16px;
          color: #2536cc;
        }
      }
    }

    & > * {
      margin: 0 6px;
    }

    .label {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #2536cc;
    }

    &:disabled {
      & > .icon {
        &::v-deep {
          .v-icon {
            color: #707080;
          }
        }
      }
      .label {
        color: #707080;
      }
    }
  }
  .action_title {
    flex: 1;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: #707080;
    text-align: center;
    .skeleton {
      height: 16px;
      width: 70px;
      margin: auto;
      ::v-deep {
        .v-skeleton-loader__bone {
          height: 12px;
        }
      }
    }
  }
  .actions-generate_docx {
    position: relative;
    &.template-menu {
      position: absolute;
      bottom: 0px;
      left: 0px;
    }
  }
  .actions_separator {
    width: 40%;
    height: 1px;
    background: #b6bdff;
    margin: auto;
    margin-top: 24px;
    &.loading {
      background: #dcdcdc;
    }
  }
  .action_btn_skeleton {
    margin: auto;
    width: 100%;
    height: 48px;
    border-radius: 5px;
    margin-top: 24px;

    ::v-deep {
      .v-skeleton-loader__bone {
        height: 48px;
      }
    }
  }
}
</style>
