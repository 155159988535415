<template>
  <div class="email_item_container">
    <!-- HEADER -->
    <folder-control-header
      :expanded="expanded"
      :control="control"
      @expandChange="expanded = !expanded"
    />

    <!-- BODY -->
    <template v-if="expanded">
      <div
        class="item_body"
        :class="{
          error_body: control.isError,
          control_success: control.isSuccess,
        }"
      >
        <div class="separator" />
        <div class="item_content">
          <!-- CONTROL ERROR REASONS -->
          <div v-if="control.isError" class="email_details">
            <div class="item__title">
              {{ $t("utils.errorReason") }}
            </div>
            <div class="message__container">
              <div
                v-for="message in control.reasons"
                :key="message"
                class="message"
                v-text="message"
              />
            </div>
          </div>
          <!-- CONTROL QUESTION -->
          <div class="control_details_item">
            <div class="detail_title" v-text="$t('utils.fieldsConcerned')" />
            <div class="control_questions">
              <FolderPreviewQuestion
                v-for="(question, i) in control.questions"
                :key="`q-${i}${question.id + 1}`"
                :editable="false"
                :selectable="false"
                :question="question"
              />
            </div>
            <div v-if="control.isError" class="control_question_action">
              <v-btn
                depressed
                class="purple lighten-5 purple--text"
                small
                @click="openSendCosmoformDialog()"
              >
                <BaseIcon left small icon="$mdiSend" />
                <span v-text="$tc('utils.sendFields', 2)" />
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FolderControlHeader from "./FolderControlHeader.vue";
import FolderPreviewQuestion from "@/modules/Folder/Components/FolderPreviewQuestion";
import { DocumentControl } from "@/core/Models/DocumentControl";

export default {
  name: "FolderControlItem",
  components: {
    FolderControlHeader,
    FolderPreviewQuestion,
  },
  props: {
    control: {
      type: DocumentControl,
      required: true,
    },
  },
  data() {
    return {
      expanded: false,
    };
  },
  computed: {
    ...mapGetters({ isArchivedFolder: "folder/isArchivedFolder" }),
  },
  methods: {
    openSendCosmoformDialog() {
      this.$store.commit("folder/SET_ACTIVE_QUESTIONS", this.control.questions);
      this.$store.commit("folder/SET_ACTIVE_CONTROL", this.control);
      this.$store.commit("folder/SET_MODAL_SEND_COSMO_FORM", true);
    },
  },
};
</script>

<style lang="scss" scoped>
.email_item_container {
  background: #ffffff;
  margin-bottom: 24px;
  border-radius: 10px;
  box-shadow: 0px 0px 1px #cfcfdc, 0px 0px 1px rgba(37, 54, 204, 0.2);
  overflow: hidden;

  .item_body {
    border-left: 32px solid #b6bdff;
    position: relative;
    &.control_success {
      border-left: 32px solid #18a300;
    }
    &.error_body {
      border-left: 32px solid #ff5267;
    }

    .separator {
      width: calc(100% - 80px);
      margin: auto;
      height: 1px;
      background: #eeeef7;
    }
    .item_content {
      padding: 24px;

      .email_details {
        padding: 16px 24px;
        .email_detail_item {
          .title {
            font-family: "Inter";
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            color: #353542;
          }
        }
      }
      .control_details_item {
        padding: 16px 24px;
        .detail_title {
          font-family: "Inter";
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 18px;
          margin-bottom: 24px;
        }
        .control_questions {
          display: grid;
          padding: 8px;
          padding-bottom: 4px;
          grid-column-gap: 16px;
          grid-row-gap: 16px;
          grid-template-columns: 100%;
          max-height: 248px;
          overflow-y: auto;
          overflow-x: hidden;
          @media (min-width: 1200px) {
            grid-template-columns: repeat(2, calc(calc(100% - 16px) / 2));
          }
          @media (min-width: 1400px) {
            grid-template-columns: repeat(3, calc(calc(100% - 32px) / 3));
          }
          &::-webkit-scrollbar {
            width: 8px;
          }
          &::-webkit-scrollbar-track {
            background: #fff;
            border-radius: 3px;
            border: 1px solid #eeeef7;
          }

          &::-webkit-scrollbar-thumb {
            background: #b6bdff;
            border-radius: 3px;
          }
        }
        .control_question_action {
          padding: 16px 24px;
          display: flex;
          justify-content: flex-end;
        }
      }
      .item__title {
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px; /* 150% */
        margin-bottom: 24px;
      }
      .message__container {
        display: flex;
        flex-direction: column;
        border-radius: 5px;
        padding: 16px;
        gap: 10px;
        border: 0.5px solid #cfcfdc;
        background: #fff;
        .message {
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }
}
</style>
