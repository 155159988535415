<template>
  <BaseDialog
    max-width="800"
    :value="dialogState"
    :persistent="isSubmiting"
    @click:outside="reset()"
    @keydown.esc="reset()"
  >
    <template #modal>
      <BaseCosmoCard>
        <template #title>
          <div class="modal_header">
            <BaseTitle
              v-text="
                questionTemplate && questionTemplate.id
                  ? $t('utils.editQuestionTemplate')
                  : $t('utils.createQuestionTemplate')
              "
            />
            <BaseButtonIcon
              class="close_icon"
              icon="$mdiClose"
              @click="reset()"
            />
          </div>
        </template>
        <div class="modal_content">
          <!-- QUESTION TYPE SECTION-->
          <div class="input_group_title" v-text="$t('utils.fieldType')" />
          <div class="input_group">
            <base-autocomplete
              v-model="form.typeQuestion"
              :items="typeItems"
              :disabled="!!questionTemplate"
              :placeholder="$t('question.chooseAType')"
              :label="`${$t('workflow.status.question.type')}*`"
              item-text="name"
              item-value="type"
              :error-messages="typeErrors"
              :has-error="typeErrors.length > 0"
              @blur="$v.form.typeQuestion.$touch()"
            />
          </div>
          <!-- FIELD CUSTOMIZATION SECTION -->
          <div
            class="input_group_title"
            v-text="$t('utils.fieldCustomization')"
          />
          <div class="input_group">
            <!-- SLUG -->
            <BaseTextField
              v-model="form.slug"
              :error-messages="slugErrors"
              :has-error="slugErrors.length > 0"
              :disabled="!!questionTemplate"
              :label="$t('utils.dataSlug')"
              @blur="$v.form.slug.$touch()"
            />
            <!-- NAME -->
            <BaseTextField
              v-model="form.name"
              :label="`${$t('utils.dataName')}*`"
              :error-messages="nameErrors"
              :has-error="nameErrors.length > 0"
              @blur="$v.form.name.$touch()"
              @change="propertyChange('name')"
            />
            <!-- LABEL -->
            <BaseTextField
              v-model="form.label"
              :label="`${$t('utils.label')}*`"
              :error-messages="labelErrors"
              :has-error="labelErrors.length > 0"
              @blur="$v.form.label.$touch()"
              @change="propertyChange('label')"
            />
          </div>
          <!-- OPTION SECTION -->
          <template v-if="isQuestionRequireOptions">
            <div class="input_group_title" v-text="$t('utils.options')" />
            <div class="input_group">
              <div v-if="!isFetchingOptions">
                <div
                  v-for="(v, index) in $v.form.options.$each.$iter"
                  :key="index"
                  class="option_item"
                >
                  <BaseTextField
                    :ref="`option${+index + 1}`"
                    v-model.trim="form.options[index].name"
                    :error-messages="optionErrors[index]"
                    :label="
                      $t('course.forms.question.option.label', {
                        number: +index + 1,
                      })
                    "
                    :has-error="optionErrors[index].length > 0"
                    :placeholder="
                      $t('course.forms.question.option.placeholder', {
                        number: +index + 1,
                      })
                    "
                    clearable
                    class="option_name"
                    @blur="v.name.$touch()"
                  />
                  <BaseTextField
                    v-model.trim="form.options[index].slug"
                    :label="
                      $t('course.forms.question.option.slug', {
                        number: +index + 1,
                      })
                    "
                    :placeholder="
                      $t('course.forms.question.option.slug', {
                        number: +index + 1,
                      })
                    "
                    :error-messages="optionSlugErrors[index]"
                    :has-error="optionSlugErrors[index].length > 0"
                    clearable
                    class="option_slug"
                    @input="v.slug.$touch()"
                  />
                  <div class="option_item_actions">
                    <BaseButtonIcon
                      :disabled="index == 0"
                      color="primary"
                      class="rounded"
                      icon="$mdiArrowUpThick"
                      x-small
                      @click="moveOption(+index, false)"
                    />
                    <BaseButtonIcon
                      :disabled="
                        index ==
                        Object.keys($v.form.options.$each.$iter).length - 1
                      "
                      color="primary"
                      class="rounded mx-2"
                      icon="$mdiArrowDownThick"
                      x-small
                      @click="moveOption(+index, true)"
                    />
                    <BaseButtonIcon
                      :disabled="form.options.length < 2"
                      color="secondary"
                      x-small
                      icon="$mdiTrashCanOutline"
                      @click="deleteOption(index)"
                    />
                  </div>
                </div>
                <div v-if="hasFreeOption" class="option_item">
                  <BaseTextField
                    :value="$t('utils.other')"
                    :label="
                      $t('course.forms.question.option.label', {
                        number: +form.options.length + 1,
                      })
                    "
                    disabled
                    clearable
                    class="option_name"
                  />
                  <div class="option_item_actions">
                    <BaseButtonIcon
                      disabled
                      color="primary"
                      class="rounded"
                      icon="$mdiArrowUpThick"
                      x-small
                    />
                    <BaseButtonIcon
                      disabled
                      color="primary"
                      class="rounded ml-2"
                      icon="$mdiArrowDownThick"
                      x-small
                    />
                    <BaseButtonIcon
                      color="error"
                      class="ml-2"
                      x-small
                      icon="$mdiTrashCanOutline"
                      @click="hasFreeOption = false"
                    />
                  </div>
                </div>
                <div class="d-flex justify-center">
                  <BaseButton icon="$mdiPlus" class="mr-3" @click="addOption()">
                    {{ $t("course.forms.question.utils.addOption") }}
                  </BaseButton>

                  <BaseButton
                    v-if="
                      (form.typeQuestion === 'RADIOBUTTON' ||
                        form.typeQuestion === 'CHECKBOX') &&
                      !hasFreeOption
                    "
                    icon="$mdiPlus"
                    @click="addOptionOther()"
                  >
                    {{ $t("course.forms.question.utils.addOptionOther") }}
                  </BaseButton>
                </div>
              </div>
              <div v-else>
                <div
                  v-for="n in 3"
                  :key="n"
                  class="d-flex justify-space-between align-center mb-3"
                >
                  <BaseSkeletonLoader type="chip" />
                  <div class="d-flex flex-wrap align-center">
                    <BaseSkeletonLoader
                      height="20px"
                      width="20px"
                      type="image"
                      class="mr-2"
                    />
                    <BaseSkeletonLoader
                      height="20px"
                      width="20px"
                      type="image"
                      class="mr-2"
                    />
                    <BaseSkeletonLoader
                      height="20px"
                      width="20px"
                      type="image"
                    />
                  </div>
                </div>
                <div class="d-flex justify-center">
                  <BaseSkeletonLoader type="button" />
                </div>
              </div>
            </div>
          </template>
          <!-- HELP SECTION -->
          <div v-if="isHelpSectionOpen" class="input_group">
            <div class="help_section">
              <div class="help_section_header">
                <BaseIcon medium icon="$mdiInformation" color="#353542" />
                <div class="help_title" v-text="$t('utils.help')" />
                <BaseIcon
                  icon="$mdiClose"
                  color="#CFCFDC"
                  class="ml-auto"
                  @click="removeHelpSection()"
                />
              </div>
              <BaseTextArea
                v-model="form.helpText"
                rows="3"
                :label="$t('course.forms.question.helpText.label')"
                @change="propertyChange('helpText')"
              />
              <BaseFileInput
                :val="form.helpFile"
                truncate-length="50"
                append-icon="$mdiDownload"
                :label="$t('utils.helpFile')"
                prepend-icon=""
                :clearable="false"
                outlined
                @change="helpFileChange($event)"
              />
            </div>
          </div>
        </div>
        <div class="modal_footer">
          <BaseButton
            v-if="!isHelpSectionOpen"
            outlined
            type="secondary"
            color="primary"
            class="help_btn"
            @click="isHelpSectionOpen = true"
          >
            Ajouter une aide
          </BaseButton>
          <BaseButton
            :disabled="isSubmiting"
            color="grey"
            type="secondary"
            @click="reset()"
          >
            {{ $t("utils.cancel") }}
          </BaseButton>
          <BaseButton
            :loading="isSubmiting"
            color="primary"
            type="primary"
            @click="submit()"
          >
            {{ $t("utils.save") }}
          </BaseButton>
        </div>
      </BaseCosmoCard>
    </template>
  </BaseDialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import { fileURLToFile } from "@/core/Utils/file.utils";
import QuestionTemplate from "@/core/Models/QuestionTemplate";
import {
  createQuestionTemplate,
  editQuestionTemplate,
  getQuestionTemplateOption,
} from "@/core/Services/questionTemplate.service";

export default {
  name: "QuestionTemplateCreateAndEditDialog",
  mixins: [validationMixin],
  props: {
    dialogState: {
      type: Boolean,
    },
    questionTemplate: {
      type: QuestionTemplate,
      default: () => null,
    },
    organizationId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      isSubmiting: false,
      isHelpSectionOpen: false,
      isFetchingOptions: false,
      typeItems: [
        { name: this.$t("question.types.text"), type: "TEXT" },
        { name: this.$t("question.types.textarea"), type: "TEXTAREA" },
        { name: this.$t("question.types.number"), type: "NUMBER" },
        { name: this.$t("question.types.date"), type: "DATE" },
        { name: this.$t("question.types.datetime"), type: "DATETIME" },
        { name: this.$t("question.types.select"), type: "SELECT" },
        { name: this.$t("question.types.checkbox"), type: "CHECKBOX" },
        { name: this.$t("question.types.radio"), type: "RADIOBUTTON" },
        { name: this.$t("question.types.file"), type: "FILE" },
        { name: this.$t("question.types.multiFile"), type: "MULTIPLE_FILE" },
        { name: this.$t("question.types.signature"), type: "SIGNATURE" },
        { name: this.$t("question.types.datagrid"), type: "DATAGRID" },
        { name: this.$t("question.types.rowDatagrid"), type: "ROW_DATAGRID" },
      ],
      form: {
        typeQuestion: "",
        label: "",
        name: "",
        slug: "",
        helpText: "",
        helpFile: null,
        options: [],
        deletedOptionIds: [],
      },
      hasFreeOption: false,
      updatedProps: [],
    };
  },
  validations() {
    let validations = {};
    validations.form = {
      name: {
        required,
        maxLength: maxLength(255),
      },
      slug: {
        maxLength: maxLength(255),
        notSpecialChar: (value) => {
          return value ? /^[a-zA-Z0-9_-]+$/.test(value) : true;
        },
      },
      label: {
        required,
        maxLength: maxLength(255),
      },
      typeQuestion: {
        required,
      },
    };
    if (this.isQuestionRequireOptions) {
      validations.form = {
        ...validations.form,
        options: {
          minLength: minLength(1),
          $each: {
            name: {
              required,
              maxLength: maxLength(255),
            },
            slug: {
              maxLength: maxLength(255),
              notSpecialChar: (value) => {
                return value ? /^[a-zA-Z0-9_-]+$/.test(value) : true;
              },
              isUniqueSlug: (value) => {
                return (
                  this.form.options.filter((o) => o.slug == value).length <= 1
                );
              },
            },
          },
        },
      };
    }
    return validations;
  },
  computed: {
    isQuestionRequireOptions() {
      const requireOptionsRegex = /^SELECT|RADIOBUTTON|CHECKBOX|DATAGRID|ROW_DATAGRID$/;
      return (
        this.form.typeQuestion &&
        requireOptionsRegex.test(this.form.typeQuestion)
      );
    },
    typeErrors() {
      const errors = [];
      if (!this.$v.form.typeQuestion.$dirty) return errors;
      if (!this.$v.form.typeQuestion.required)
        errors.push(this.$t("course.forms.question.typeQuestion.required"));
      return errors;
    },
    slugErrors() {
      const errors = [];
      if (!this.$v.form.slug.$dirty) return errors;
      if (!this.$v.form.slug.maxLength) {
        errors.push(
          this.$t("course.forms.question.slug.maxLength", { n: 255 })
        );
      }
      if (!this.$v.form.slug.notSpecialChar) {
        errors.push(
          this.$t("course.forms.question.slug.specialCharError", { n: 255 })
        );
      }
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.form.name.$dirty) return errors;
      if (!this.$v.form.name.required) {
        errors.push(this.$t("course.forms.question.name.required"));
      }
      if (!this.$v.form.name.maxLength) {
        errors.push(
          this.$t("course.forms.question.name.maxLength", { n: 255 })
        );
      }
      return errors;
    },
    labelErrors() {
      const errors = [];
      if (!this.$v.form.label.$dirty) return errors;
      if (!this.$v.form.label.required) {
        errors.push(this.$t("course.forms.question.label.requiredQuestion"));
      }
      if (!this.$v.form.label.maxLength) {
        errors.push(
          this.$t("course.forms.question.label.maxLengthQuestion", { n: 255 })
        );
      }
      return errors;
    },
    optionErrors() {
      const errors = [];
      if (!this.$v.form.options) {
        return errors;
      }
      for (
        let index = 0;
        index < Object.keys(this.$v.form.options.$each.$iter).length;
        index++
      ) {
        errors.push([]);
        const option = this.$v.form.options.$each.$iter[index.toString()];
        if (!option.name.$dirty) {
          continue;
        }
        if (!option.name.maxLength) {
          errors[index] = [
            this.$t("course.forms.question.option.maxLength", { number: 255 }),
          ];
          continue;
        }
        if (!option.name.required) {
          errors[index] = [this.$t("course.forms.question.option.required")];
        }
      }
      return errors;
    },
    optionSlugErrors() {
      const errors = [];
      if (!this.$v.form.options) return errors;
      for (
        let index = 0;
        index < Object.keys(this.$v.form.options.$each.$iter).length;
        index++
      ) {
        errors.push([]);
        const option = this.$v.form.options.$each.$iter[index.toString()];
        if (!option.slug.$dirty) continue;
        if (!option.slug.maxLength) {
          errors[index] = [
            this.$t("course.forms.question.slug.maxLength", { n: 255 }),
          ];
          continue;
        }
        if (!option.slug.notSpecialChar) {
          errors[index] = [
            this.$t("course.forms.question.slug.specialCharError", { n: 255 }),
          ];
          continue;
        }
        if (!option.slug.isUniqueSlug) {
          errors[index] = [
            this.$t("course.forms.question.slug.duplicatedSlug"),
          ];
          continue;
        }
      }
      return errors;
    },
  },
  watch: {
    isQuestionRequireOptions(newVal) {
      if (newVal && this.form.options.length < 1) this.addOption();
    },
    async dialogState(val) {
      if (val && this.questionTemplate) this.initializeForm();
    },
  },
  methods: {
    async initializeForm() {
      if (!this.questionTemplate) return;
      this.form.label = this.questionTemplate.label;
      this.form.name = this.questionTemplate.name;
      this.form.slug = this.questionTemplate.slug;
      this.form.typeQuestion = this.questionTemplate.typeQuestion;
      this.hasFreeOption = this.questionTemplate.hasFreeOption;
      this.form.helpText = this.questionTemplate.helpText;
      if (this.questionTemplate.helpFile) {
        this.form.helpFile = await fileURLToFile(
          this.questionTemplate.helpFile,
          this.questionTemplate.helpFile.split("/").reverse()[0]
        );
      }
      if (this.questionTemplate.helpFile || this.questionTemplate.helpText)
        this.isHelpSectionOpen = true;
      this.fetchQuestionTemplateOption();
    },
    async submit() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) return;
      this.isSubmiting = true;
      if (!this.questionTemplate) await this.createQuestionTemplate();
      else await this.editQuestionTemplate();
      this.isSubmiting = false;
      this.reset();
    },
    async fetchQuestionTemplateOption() {
      if (!this.isQuestionRequireOptions) return;
      this.isFetchingOptions = true;
      try {
        this.form.options = await getQuestionTemplateOption({
          organizationId: this.organizationId,
          questionTemplateId: this.questionTemplate.id,
        });
      } catch (error) {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.errorHasHappen"),
          type: "ERROR",
        });
      }
      this.isFetchingOptions = false;
    },
    async createQuestionTemplate() {
      try {
        await createQuestionTemplate({
          organizationId: this.organizationId,
          payload: this.buildQuestion(),
        });
        this.$emit("questionTemplateCreated");
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.snackbar.fieldCreatedSuccessfully"),
          type: "SUCCESS",
        });
      } catch {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.errorHasHappen"),
          type: "ERROR",
        });
      }
    },
    async editQuestionTemplate() {
      try {
        this.$emit(
          "questionTemplateEdited",
          await editQuestionTemplate({
            organizationId: this.organizationId,
            questionTemplateId: this.questionTemplate.id,
            deletedOptionsIds: this.form.deletedOptionIds,
            payload: this.buildQuestion(),
          })
        );
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.snackbar.fieldChangedSuccessfully"),
          type: "SUCCESS",
        });
      } catch {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.errorHasHappen"),
          type: "ERROR",
        });
      }
    },
    propertyChange(property) {
      if (!this.updatedProps.includes(property))
        this.updatedProps.push(property);
    },
    buildQuestion() {
      let question = {
        ...this.form,
        hasFreeOption: this.hasFreeOption,
      };
      if (this.questionTemplate) {
        for (const key in question) {
          if (!this.updatedProps.includes(key) && key !== "options")
            delete question[key];
        }
      }
      return question;
    },
    removeHelpSection() {
      this.isHelpSectionOpen = false;
      this.form.helpText = "";
      this.form.helpFile = "";
      this.propertyChange("helpText");
      this.propertyChange("helpFile");
    },
    helpFileChange(value) {
      this.form.helpFile = value;
      this.propertyChange("helpFile");
    },
    addOption() {
      this.form.options.push({ order: this.form.options.length });
      this.orderOptions();
    },
    moveOption(index, upperOrder) {
      let temp = this.form.options[index];
      this.form.options.splice(
        index,
        1,
        this.form.options[upperOrder ? index + 1 : index - 1]
      );
      this.form.options.splice(upperOrder ? index + 1 : index - 1, 1, temp);
      this.orderOptions();
    },
    deleteOption(index) {
      /* saved options in database to delete */
      if (this.form.options[index].id) {
        this.form.deletedOptionIds.push(this.form.options[index].id);
      }
      this.form.options.splice(index, 1);
      this.orderOptions();
    },
    orderOptions() {
      this.form.options.forEach((option, index) => {
        option.order = index;
      });
    },
    addOptionOther() {
      this.hasFreeOption = true;
    },
    deleteOptionOther() {
      this.hasFreeOption = false;
    },
    reset() {
      if (this.isSubmiting) return;
      this.resetForm();
      this.$emit("close");
    },
    resetForm() {
      this.form = {
        typeQuestion: "",
        label: "",
        name: "",
        slug: "",
        helpText: "",
        helpFile: null,
        options: [],
        deletedOptionIds: [],
      };
      this.hasFreeOption = false;
      this.$v.form.$reset();
    },
  },
};
</script>

<style lang="scss" scoped>
.modal_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  padding-top: 8px;
  flex-wrap: wrap;
  .modal_title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #353542;
  }
  .close_icon {
    margin-left: auto;
  }
}
.modal_content {
  margin-top: 24px;
  .input_group_title {
    color: #353542;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 24px;
  }
  .input_group {
    padding: 0 16px;
  }
  .option_item {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    gap: 8px;
    .option_slug {
      flex: 1;
    }
    .option_slug {
      flex: none;
      width: 200px;
    }
    .option_item_actions {
      display: flex;
      align-items: center;
      flex: none;
      width: 76px;
      margin-top: -26px;
    }
  }
  .help_section {
    display: flex;
    flex-direction: column;
    padding: 18px;
    gap: 8px;
    min-height: 255px;
    background: #fcfcfc;
    border: 1px solid #cfcfdc;
    border-radius: 5px;
    margin-bottom: 24px;
    .help_section_header {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      .help_title {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #353542;
        margin: 0 4px;
      }
    }
  }
}
.modal_footer {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  padding: 8px 0;
  & > * {
    margin-left: 16px;
  }
  & > .help_btn {
    margin: 0;
    margin-right: auto;
  }
}
</style>
