import { render, staticRenderFns } from "./FolderRecordItem.vue?vue&type=template&id=85945cf0&scoped=true"
import script from "./FolderRecordItem.vue?vue&type=script&lang=js"
export * from "./FolderRecordItem.vue?vue&type=script&lang=js"
import style0 from "./FolderRecordItem.vue?vue&type=style&index=0&id=85945cf0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "85945cf0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
installComponents(component, {VBtn,VCheckbox,VMenu,VTab,VTabItem,VTabs,VTabsItems})
