import Option from "./Option";

export default class QuestionTemplate {
  constructor({
    id = Number.NaN,
    slug = "",
    name = "",
    label = "",
    typeQuestion = "",
    helpText = "",
    helpFile = null,
    hasFreeOption = false,
    options = [],
  } = {}) {
    this.id = Number.parseInt(id);
    this.slug = slug;
    this.name = name;
    this.label = label;
    this.typeQuestion = typeQuestion;
    this.helpText = helpText;
    this.helpFile = helpFile;
    this.hasFreeOption = hasFreeOption;
    this.options = options ? options.map((choice) => new Option(choice)) : [];
  }
}
