import {
  createFolder,
  updateFolder,
  unarchiveFolder,
  findOrganizationFolders,
  findFolder,
  inviteFolder,
  findFolderRecords,
  getFolderCosmoformDetails,
  postInvalidateRecord,
  postValidateRecord,
  getFolderLogs,
  setFolderWorkflow,
  postStatusQuestion,
  postStatusDatatypeQuestion,
  getDataRecord,
  getFolderFiles,
  setFolderCollaboratorStatus,
  getClientOwnfolder,
  listOrganizationFolders,
  getOrganizationFolderOffersRecordsCosmoforms,
  postFolderDataToDPAE,
} from "../Services/folder.service";
import FolderAPI from "../Services/folder.api";

import Folder, { VALIDATION_STATUS } from "@/core/Models/Folder";
import Record from "@/core/Models/Record";
import Question from "@/core/Models/Question";
import Offer from "@/core/Models/Offer";
import { DocumentControl } from "@/core/Models/DocumentControl";
import OfferApi from "../Services/offer.api";
const RECORD_TYPE = {
  RECORD: "RECORD",
  COSMOFORM: "COSMOFORM",
  OFFER: "OFFER",
  EMAIL: "EMAIL",
  CONTROL: "CONTROL",
};
export default {
  namespaced: true,
  state: {
    folders: [],
    folderLogsCount: null,
    allOrganizationFolders: [],
    cosmoformDetails: [],
    foldersPromise: null,
    activeFolder: null,
    activeRecord: null,
    activeQuestion: null,
    activeQuestions: null,
    activeControl: null,
    activeCampaignId: null,
    isFetchingFolderLogs: true,
    recordToRelaunch: null,
    cosmoformToRelaunch: null,
    stopFetchingFolder: false,
    isValidationMultiple: false,
    canBeSentToGed: true,
    missingValuesInGenerationDocx: null,
    cosmoformTemplateId: null,
    offersRecordsCosmoforms: [],
    isCosmoformAction: false,
    activeCosmoform: null,
    dpaeMissingFields: [],
    templateMail: null,
    statusTemplate: null,
    modals: {
      isCosmoformDialogDisplayOpen: false,
      isRelaunchInvitationDialogOpen: false,
      isRelaunchCosmoformDialogOpen: false,
      stopFetchingFolder: false,
      isValidationMultiple: false,
      isAskInformationDialogOpen: false,
      isCreateAndEditDialogOpen: false,
      folderEditNameOpen: false,
      isFolderEditWorkflowOpen: false,
      isShowAnswerActive: false,
      isValidationDialogOpen: false,
      isFolderLogsDialogOpen: false,
      isAnswerQuestionsOpen: false,
      isSendCosmoFormOpen: false,
      isStatusQuestionCreateOpen: false,
      isFolderChangeStatusDialogOpen: false,
      isFolderAssignCollaboratorDialogOpen: false,
      isFolderChangeClient: false,
      isModalGenerateDocOpen: false,
      isArchiveDialogOpen: false,
      isSendToDPAEDialogOpen: false,
      isSendMailDialogOpen: false,
    },
  },
  mutations: {
    setTemplateMail(state, payload) {
      state.templateMail = payload;
    },

    setSendMailDialogOpen(state, payload) {
      state.modals.isSendMailDialogOpen = payload;
    },
    setTemplateStatus(state, payload) {
      state.statusTemplate = payload;
    },
    setDPAEMissingFields(state, payload) {
      state.dpaeMissingFields = payload;
    },
    setIsCosmoformAction(state, payload) {
      state.isCosmoformAction = payload;
    },
    SET_RECORD_TO_RELAUNCH(state, payload) {
      state.recordToRelaunch = payload;
    },
    SET_COSMOFORM_TO_RELAUNCH(state, payload) {
      state.cosmoformToRelaunch = payload;
    },
    setActiveCosmoform(state, payload) {
      state.activeCosmoform = payload;
    },
    SET_RELAUNCH_DIALOG_OPEN(state, payload) {
      state.modals.isRelaunchInvitationDialogOpen = payload;
    },
    SET_DPAE_DIALOG_OPEN(state, payload) {
      state.modals.isSendToDPAEDialogOpen = payload;
    },
    SET_FOLDER_RECORDS_OFFERS_COSMOFORMS(state, payload) {
      state.offersRecordsCosmoforms = [
        ...payload.map((item) => {
          if (item.verboseName === "cosmo form")
            return { ...item, type: RECORD_TYPE.COSMOFORM };
          if (item.verboseName === "offer")
            return { ...new Offer(item), type: RECORD_TYPE.OFFER };
          if (item.verboseName === "validation_schema")
            return new DocumentControl({
              ...item,
              type: RECORD_TYPE.CONTROL,
            });
          if (item.verboseName === "email")
            return { ...item, type: RECORD_TYPE.EMAIL };
          return { ...item, type: RECORD_TYPE.RECORD };
        }),
      ];
    },
    updateFolderRecoordsOffersCosmoform(state, payload) {
      state.offersRecordsCosmoforms = [
        payload,
        ...state.offersRecordsCosmoforms,
      ];
      return state.offersRecordsCosmoforms;
    },
    UPDATE_FOLDER_RECORDS_OFFER_COSMOFORM(
      state,
      { id, value, type, keyToUpdate }
    ) {
      let folderOfferRecord = state.offersRecordsCosmoforms.find(
        (recordOfferCosmoform) =>
          recordOfferCosmoform.type === type && recordOfferCosmoform.id === id
      );
      return (folderOfferRecord[keyToUpdate] = value);
    },
    UPDATE_FOLDER_RECORDS_QUESTION(state, { questionId, id, type, status }) {
      let folderOfferRecord = state.offersRecordsCosmoforms.find(
        (recordOfferCosmoform) =>
          recordOfferCosmoform.type === type && recordOfferCosmoform.id === id
      );
      folderOfferRecord.steps.forEach((step) => {
        step.questions.forEach((question) => {
          if (question.id === questionId) return (question.validation = status);
        });
      });
    },
    SET_RELAUNCH_COSMOFORM_DIALOG_OPEN(state, payload) {
      state.modals.isRelaunchCosmoformDialogOpen = payload;
    },
    setIsCosmoformDialogDisplayOpen(state, payload) {
      state.modals.isCosmoformDialogDisplayOpen = payload;
    },

    setIsValidationMultiple(state, payload) {
      state.isValidationMultiple = payload;
    },
    setCanBeSentToGed(state, payload) {
      state.canBeSentToGed = payload;
    },
    SET_STOP_FETCHING_FOLDERS(state, payload) {
      state.stopFetchingFolder = payload;
    },
    SET_FOLDER_LOG_COUNT(state, payload) {
      state.folderLogsCount = payload;
    },
    SET_FETCHING_FOLDER_LOGS(state, payload) {
      state.isFetchingFolderLogs = payload;
    },
    SET_ALL_ORGANIZATION_FOLDERS(state, payload) {
      state.allOrganizationFolders = [...payload];
    },
    SET_FOLDERS(state, payload) {
      state.folders = payload
        ? payload.map((folder) => new Folder(folder))
        : [];
    },
    SET_ACTIVE_FOLDER(state, payload) {
      state.activeFolder = payload ? new Folder(payload) : null;
    },
    setOfferListOffer(state, { offerId, payload }) {
      state.offersRecordsCosmoforms.splice(
        state.offersRecordsCosmoforms.findIndex(
          (o) => o.type === RECORD_TYPE.OFFER && o?.id == offerId
        ),
        1,
        new Offer(payload)
      );
    },
    UPDATE_ACTIVE_RECORD(state, { recordId, status }) {
      if (!state.activeFolder) return;
      state.activeFolder = {
        ...state.activeFolder,
        records: state.activeFolder.records?.map((record) =>
          record.id === recordId
            ? {
                ...record,
                validationStatus:
                  record.validationStatus === VALIDATION_STATUS.UPDATE_REQUESTED
                    ? VALIDATION_STATUS.UPDATE_REQUESTED
                    : status,
                // we dont change the status if it is update requested
              }
            : { ...record }
        ),
      };
    },
    setOfferMemberAndSignedFile(state, { offerId, member, signedFiles }) {
      const offer = state.offersRecordsCosmoforms.find(
        (offer) => offer.type === RECORD_TYPE.OFFER && offer.id === offerId
      );
      offer.yousignMembers = member.map((youMember, index) => ({
        ...youMember,
        ...offer.yousignMembers[index],
      }));
      offer.signedFiles = signedFiles;
    },
    SET_FOLDER_COSMOFORM_DETAILS(state, payload) {
      state.cosmoformDetails = payload;
    },
    SET_ACTIVE_FOLDER_RECORD(state, { recordId, payload }) {
      if (!state.activeFolder || !state.activeFolder.records.length) return;
      state.activeFolder.records.splice(
        state.activeFolder.records.findIndex((r) => r.id == recordId),
        1,
        new Record(payload)
      );
    },
    SET_ACTIVE_RECORD(state, payload) {
      state.activeRecord = payload ? new Record(payload) : null;
    },
    UPDATE_RECORD_QUESTION(state, { recordId, question }) {
      state.activeFolder.updateRecordQuestion(recordId, question);
    },

    SET_ACTIVE_QUESTION(state, payload) {
      state.activeQuestion = payload ? new Question(payload) : null;
    },
    SET_ACTIVE_QUESTIONS(state, payload) {
      state.activeQuestions = payload
        ? payload.map((q) => new Question(q))
        : null;
    },
    SET_ACTIVE_CONTROL(state, payload) {
      state.activeControl = payload;
    },
    SET_ACTIVE_COMPAIGN_ID(state, payload) {
      state.activeCampaignId = payload;
    },
    setAskInformationDialog(state, payload) {
      state.modals.isAskInformationDialogOpen = payload;
    },
    SET_MODAL_VALIDATION_DIALOG(state, payload) {
      state.modals.isValidationDialogOpen = payload;
    },
    SET_MODAL_SHOW_ANSWER(state, payload) {
      state.modals.isShowAnswerActive = payload;
    },
    SET_ACTIVE_FOLDER_LOGS(state, payload) {
      state.activeFolder.logs = payload;
    },
    setFolderLogsDialogOpen(state, payload) {
      state.modals.isFolderLogsDialogOpen = payload;
    },
    setModalCreateAndEditFolder(state, payload) {
      state.modals.isCreateAndEditDialogOpen = payload;
    },
    SET_MODAL_FOlDER_EDIT_NAME(state, payload) {
      state.modals.folderEditNameOpen = payload;
    },
    SET_MODAL_FOLDER_EDIT_WORKFLOW(state, payload) {
      state.modals.isFolderEditWorkflowOpen = payload;
    },
    SET_MODAL_ANWER_QUESTION_STATUS(state, payload) {
      state.modals.isAnswerQuestionsOpen = payload;
    },
    SET_MODAL_SEND_COSMO_FORM(state, payload) {
      state.modals.isSendCosmoFormOpen = payload;
    },
    SET_COSMO_FORM_TEMPLATE_ID(state, payload) {
      state.cosmoformTemplateId = payload;
    },
    setModalStatusQuestionCreateDialog(state, payload) {
      state.modals.isStatusQuestionCreateOpen = payload;
    },
    setModalFolderChangeStatusDialog(state, payload) {
      state.modals.isFolderChangeStatusDialogOpen = payload;
    },
    setModalGenerateDocxOpen(state, payload) {
      state.modals.isModalGenerateDocOpen = payload;
    },
    //#endregion
    setFolderChangeClient(state, payload) {
      state.modals.isFolderChangeClient = payload;
    },
    SET_MODAL_ASSIGN_COLLABORATOR_STATUS(state, payload) {
      state.modals.isFolderAssignCollaboratorDialogOpen = payload;
    },
    setMissingValuesInDocx(state, payload) {
      state.missingValuesInGenerationDocx = payload;
    },
    SET_MODAL_FOLDER_ARCHIVE_STATUS(state, payload) {
      state.modals.isArchiveDialogOpen = payload;
    },
  },
  actions: {
    // fetch all folders organization
    getAllOrganizationFolders: async (
      { commit },
      { organizationId, params = "" }
    ) => {
      const response = await listOrganizationFolders(organizationId, params);
      commit("SET_ALL_ORGANIZATION_FOLDERS", response.data.results);
      return response.data.results;
    },
    async fetchOfferMemberAndSignedFiles(
      { commit },
      { organizationId, procedureId, page, offerId }
    ) {
      const memberResponse = await OfferApi.getOrganizationFolderOfferMember(
        organizationId,
        procedureId,
        page
      );
      const fileSignedResponse = await OfferApi.getOrganizationFolderOfferSignedFile(
        organizationId,
        procedureId,
        page
      );
      commit("setOfferMemberAndSignedFile", {
        member: [...memberResponse.data.results],
        signedFiles: [...fileSignedResponse.data.results],
        offerId,
      });
    },
    async fetchOfferFileList(
      { commit, state },
      { organizationId, folderId, offerId }
    ) {
      let page = 1;
      let payload = new Offer({
        ...state.offersRecordsCosmoforms.find(
          (o) => o.type === RECORD_TYPE.OFFER && o.id === offerId
        ),
      });
      do {
        const res = await OfferApi.getOrganizationFolderOfferFileList(
          organizationId,
          folderId,
          offerId,
          page
        );
        const offerFilesPage = res.data.results;
        payload.files = [...payload.files, ...offerFilesPage];
        page = res.data.next;
      } while (page);
      return commit("setOfferListOffer", { offerId, payload });
    },
    //#region INITIALIZATION
    // Fetch folders from API
    async fetchFolders(
      { commit, state },
      { organizationId, page, pageSize, queryString }
    ) {
      await commit("SET_FOLDERS", []);
      const res = await findOrganizationFolders({
        organizationId,
        page,
        pageSize,
        queryString,
      });
      if (res.data) {
        commit("SET_FOLDERS", [...state.folders, ...res.data.results]);
        return {
          count: res.data.count,
          next: res.data.next,
          previous: res.data.previous,
        };
      }
      return {
        count: 0,
        next: 0,
        previous: 0,
      };
    },
    // Reset folders
    resetFolders({ commit }) {
      commit("SET_FOLDERS", []);
    },
    async fetchActiveFolder({ commit }, { organizationId, folderId }) {
      // commit("SET_ACTIVE_FOLDER", null);
      const folder = (await findFolder(organizationId, folderId)).data;
      commit("SET_ACTIVE_FOLDER", folder);
      return folder;
    },
    // Reset active folder
    resetActiveFolder({ commit }) {
      commit("SET_ACTIVE_FOLDER", null);
    },
    //#endregion

    //#region Folder CRUD
    createFolder({ state, commit }, payload) {
      const { organizationId } = payload;
      return createFolder(organizationId, payload).then((res) => {
        commit("SET_FOLDERS", [...state.folders, res.data]);
        return res;
      });
    },
    async updateFolder(
      { state, commit },
      { organizationId, folderId, payload }
    ) {
      let res = await updateFolder(organizationId, folderId, payload);
      commit("SET_FOLDERS", [
        ...state.folders.filter((f) => f.id != res.data.id),
        res.data,
      ]);
      commit("SET_ACTIVE_FOLDER", { ...state.activeFolder, ...res.data });
    },
    async unarchiveFolder({ state, commit }, { organizationId, folderId }) {
      let res = await unarchiveFolder({ organizationId, folderId });
      commit("SET_FOLDERS", [
        ...state.folders.filter((f) => f.id != res.data.id),
        res.data,
      ]);
      commit("SET_ACTIVE_FOLDER", { ...state.activeFolder, ...res.data });
    },
    async inviteFolder(_, { organizationId, folderId, payload }) {
      await inviteFolder(organizationId, folderId, payload);
    },
    async postDPAE(_, { organizationId, folderId }) {
      await postFolderDataToDPAE({ organizationId, folderId });
    },
    setWorkflow({ state, commit }, { organizationId, folderId, payload }) {
      return setFolderWorkflow(organizationId, folderId, payload).then(
        (res) => {
          const folder = res.data;
          commit("SET_ACTIVE_FOLDER", {
            ...state.activeFolder,
            ...folder,
          });
        }
      );
    },
    //#endregion
    //#endregion
    //#region Folder Client CRUD
    async fetchClientOwnfolder(
      { state, commit },
      { organizationId, folderId }
    ) {
      const res = await getClientOwnfolder(organizationId, folderId);
      commit("SET_ACTIVE_FOLDER", {
        ...state.activeFolder,
        owners: res.data.results,
      });
      return [...res.data.results];
    },
    //#endregion
    //#region Logs CRUD
    //read
    // get first page of folderLog
    async getFolderLogs({ state, commit }, { organizationId, folderId, page }) {
      commit("SET_FETCHING_FOLDER_LOGS", true);
      const response = await getFolderLogs(organizationId, folderId, page);
      commit("SET_FOLDER_LOG_COUNT", response.data.count);
      const oldFolderLogs =
        page !== 1 && state.activeFolder.logs
          ? [...state.activeFolder.logs]
          : [];
      commit("SET_ACTIVE_FOLDER", {
        ...state.activeFolder,

        logs: [...oldFolderLogs, ...response.data.results],
      });
      commit("SET_FETCHING_FOLDER_LOGS", false);
      return response.data;
    },
    //#endregion
    //#region Record CRUD
    async fetchActiveFolderRecords(
      { state, commit },
      { organizationId, folderId }
    ) {
      commit("SET_ACTIVE_FOLDER", {
        ...state.activeFolder,
        records: [],
      });

      let page = 1;

      while (page) {
        let res = await findFolderRecords({ organizationId, folderId, page });
        let records = res.data.results;
        if (!state.activeFolder) return;
        commit("SET_ACTIVE_FOLDER", {
          ...state.activeFolder,
          records: [...state.activeFolder.records, ...records],
        });
        page = res.data.next;
      }

      return state.activeFolder;
    },
    async fetchActiveFolderCosmoformDetails(
      { commit },
      { organizationId, folderId }
    ) {
      async function fetchCosmoformDetails(page = 1) {
        const res = await getFolderCosmoformDetails({
          organizationId,
          folderId,
          page,
        });
        if (res?.data?.next) {
          return [
            ...res.data.results,
            ...(await fetchCosmoformDetails({
              organizationId,
              folderId,
              page,
            })),
          ];
        }
        return res.data.results;
      }
      const comoformDetails = await fetchCosmoformDetails();
      commit("SET_FOLDER_COSMOFORM_DETAILS", comoformDetails);
      return comoformDetails;
    },
    async fetchActiveFolderRecord(
      { commit },
      { organizationId, folderId, recordId }
    ) {
      const res = await getDataRecord(organizationId, folderId, recordId);
      commit("SET_ACTIVE_FOLDER_RECORD", { recordId, payload: res.data });
      return res.data;
    },
    async fetchActiveFolderFiles(_, { organizationId, folderId }) {
      const response = await getFolderFiles(organizationId, folderId);
      return response.data;
    },
    resetActiveFolderRecords({ state, commit }) {
      commit("SET_ACTIVE_FOLDER", {
        ...state.activeFolder,
        records: [],
      });
    },
    async getOrganizationOffersRecordFolder({ commit }, payload) {
      const response = await getOrganizationFolderOffersRecordsCosmoforms(
        payload
      );
      commit("SET_FOLDER_RECORDS_OFFERS_COSMOFORMS", response.data);
    },
    async invalidateRecordAnswers(
      { commit, state },
      { organizationId, folderId, recordId, questions, payload }
    ) {
      await postInvalidateRecord(organizationId, folderId, recordId, payload);
      questions.forEach((question) => {
        if (question.validation !== "PENDING") return;
        else {
          commit("UPDATE_FOLDER_RECORDS_QUESTION", {
            id: recordId,
            questionId: question.id,
            status: "UPDATE_REQUESTED",
            type: "RECORD",
          });
          commit("UPDATE_FOLDER_RECORDS_OFFER_COSMOFORM", {
            id: recordId,
            type: "RECORD",
            keyToUpdate: "validationStatus",
            value: VALIDATION_STATUS.UPDATE_REQUESTED,
          });
          commit("SET_ACTIVE_FOLDER", {
            ...state.activeFolder,
            validationStatus: VALIDATION_STATUS.UPDATE_REQUESTED,
          });
        }
      });
    },
    async validateRecordAnswers(
      { commit, state },
      { organizationId, folderId, recordId, questions }
    ) {
      await postValidateRecord({
        organizationId,
        folderId,
        recordId,
        payload: {
          answers: questions.map((q) => q.answer.id),
        },
      });
      function getStatus(status) {
        if (status === VALIDATION_STATUS.UPDATED)
          return VALIDATION_STATUS.UPDATED;
        if (status === VALIDATION_STATUS.UPDATE_REQUESTED)
          return VALIDATION_STATUS.UPDATE_REQUESTED;
        else return VALIDATION_STATUS.VALIDATED;
      }

      const status = getStatus(state.activeFolder.validationStatus);
      questions.forEach((question) => {
        if (question.validation !== "PENDING") return;
        else {
          commit("UPDATE_FOLDER_RECORDS_QUESTION", {
            id: recordId,
            questionId: question.id,
            status: "VALIDATED",
            type: "RECORD",
          });
          commit("UPDATE_FOLDER_RECORDS_OFFER_COSMOFORM", {
            id: recordId,
            type: "RECORD",
            keyToUpdate: "validationStatus",
            value: status,
          });
          commit("SET_ACTIVE_FOLDER", {
            ...state.activeFolder,
            validationStatus:
              state.activeFolder.validationStatus ===
              VALIDATION_STATUS.UPDATE_REQUESTED
                ? VALIDATION_STATUS.UPDATE_REQUESTED
                : VALIDATION_STATUS.VALIDATED,
          });
        }
      });
    },
    //#endregion
    //#region Active RECORD & Active QUESTION
    setActiveRecord({ commit }, payload) {
      commit("SET_ACTIVE_RECORD", payload);
    },
    setActiveQuestion({ commit }, payload) {
      commit("SET_ACTIVE_QUESTION", payload);
    },
    setQuestionValidationModal({ commit }, payload) {
      commit("SET_MODAL_VALIDATION_DIALOG", payload);
    },
    setShowAnswerModal({ commit }, payload) {
      commit("SET_MODAL_SHOW_ANSWER", payload);
    },
    //#endregion
    //#region Workflow Status question CRUD
    async createStatusQuestion(
      _,
      { organizationId, folderId, statusId, payload }
    ) {
      const res = await postStatusQuestion(
        organizationId,
        folderId,
        statusId,
        payload
      );
      return res;
    },
    async createStatusDatatypeQuestion(
      _,
      { organizationId, folderId, statusId, payload }
    ) {
      const res = await postStatusDatatypeQuestion(
        organizationId,
        folderId,
        statusId,
        payload
      );
      return res;
    },
    //#endregion
    //#region Workflow Status question answer CRUD
    async postOrganizationFolderStatusQuestionAnswerGed(
      _,
      { organizationId, folderId, workflowStatusId, questionId, answerId }
    ) {
      const res = await FolderAPI.postOrganizationFolderStatusQuestionAnswerGed(
        organizationId,
        folderId,
        workflowStatusId,
        questionId,
        answerId
      );
      return res;
    },
    //#endregion
    //#region Folder Status
    setFolderCollaboratorStatus(_, { organizationId, folderId, payload }) {
      return setFolderCollaboratorStatus(organizationId, folderId, payload);
    },
    //#endregion
  },

  getters: {
    getAllOrganizationFolders: (state) => state.allOrganizationFolders,
    isFoldersLoading: (state) => !!state.foldersPromise,
    filteredFolders: (state) => (searchValue, status, workflow) => {
      const folders = state.folders.map((folder) => {
        return {
          ...folder,
          statusName: status.find((statut) => statut.id === folder.status)
            ?.name,
          workflowName: workflow.find((wf) => wf.id === folder.workflow)?.name,
        };
      });
      return folders.filter((folder) => {
        return (
          (folder?.name &&
            folder.name.toLowerCase().includes(searchValue.toLowerCase())) ||
          folder.statusName
            ?.toLowerCase()
            .includes(searchValue.toLowerCase()) ||
          folder.workflowName?.toLowerCase().includes(searchValue.toLowerCase())
        );
      });
    },
    isActiveFolderSet: (state) => state.activeFolder != null,
    isArchivedFolder: (state) => state.activeFolder?.state == "CLOSED",
  },
};
